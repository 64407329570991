@use "../abstracts/mixins";
@use "../abstracts/colors";
@use "../abstracts/typography";

.rt-directions-pane {
  --_rt-directions-pane-background-color: var(--rt-color-neutral);
  --_rt-directions-pane-padding-block: 30px;

  // * close icon
  --_rt-directions-pane-close-icon-size: 16px;
  --_rt-directions-pane-close-icon-offset-top: 20px;
  --_rt-directions-pane-close-icon-offset-right: 20px;

  // * travel modes
  --_rt-directions-pane-travel-modes-max-size: 49px;
  --_rt-directions-pane-travel-modes-gap: 32px;

  // * addresses area
  --_rt-directions-pane-addresses-padding: 12px 27px;
  --_rt-directions-pane-addresses-border: 1px solid transparent;
  --_rt-directions-pane-addresses-background-color: white;
  --_rt-directions-pane-addresses-border-radius: 0;
  --_rt-directions-pane-addresses-icon-width: 19px;
  --_rt-directions-pane-addresses-icon-gap: 13px;
  --_rt-directions-pane-addresses-font-size: 15px;

  // * instructions area
  --_rt-directions-pane-instructions-label-icon-width: 28px;
}

// * transition
// We use :where() to help lowering the specificity
// since we need to re-assign the transform property when the
// "--visible" class is applied later in the code
:where(.rt-map-list-container--list-right .rt-directions-pane) {
  transform: translateX(101%);
}

// If we chose a "--list-left" container, the directions pane needs to
// slide from left to right
:where(.rt-map-list-container--list-left .rt-directions-pane) {
  transform: translateX(-101%);
}

.rt-directions-pane {
  position: relative; // necessary for the close icon

  background-color: var(--_rt-directions-pane-background-color);
  padding-block: var(--_rt-directions-pane-padding-block);
  overflow-y: auto;

  visibility: hidden;

  transition-property: transform, visibility;
  transition-duration: 300ms;

  &--visible {
    transform: translateX(0);
    visibility: visible;
  }

  @include mixins.mq(desktop, down) {
    display: none;
  }
}

// * close icon
.rt-directions-pane .rt-btn-close {
  position: absolute;
  top: var(--_rt-directions-pane-close-icon-offset-top);
  right: var(--_rt-directions-pane-close-icon-offset-right);

  img {
    width: var(--_rt-directions-pane-close-icon-size);
    height: var(--_rt-directions-pane-close-icon-size);

    object-fit: contain;
  }
}

// * travel modes
.rt-travel-modes {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: var(--_rt-directions-pane-travel-modes-gap);

  margin-bottom: 37px;
}

.rt-travel-mode__icon {
  max-width: var(--_rt-directions-pane-travel-modes-max-size);
  max-height: var(--_rt-directions-pane-travel-modes-max-size);

  &--selected {
    display: none;
  }
}

.rt-travel-mode--selected {
  filter: brightness(0) saturate(100%) invert(16%) sepia(90%) saturate(1886%) hue-rotate(210deg)
    brightness(97%) contrast(95%);
}

// * addresses area
.rt-addresses-area {
  display: grid;
  gap: 16px;

  margin-bottom: 40px;
}

.rt-address-origin .rt-autocomplete {
  --_rt-autocomplete-padding: var(--_rt-directions-pane-addresses-padding);
  --_rt-autocomplete-border: var(--_rt-directions-pane-addresses-border);
  --_rt-autocomplete-background-color: var(--_rt-directions-pane-addresses-background-color);
  --_rt-autocomplete-border-radius: var(--_rt-directions-pane-addresses-border-radius);
  --_rt-autocomplete-width-icons: var(--_rt-directions-pane-addresses-icon-width);
  --_rt-autocomplete-gap-icons: var(--_rt-directions-pane-addresses-icon-gap);
  --_rt-autocomplete-font-size: var(--_rt-directions-pane-addresses-font-size);
}

.rt-address-destination {
  display: flex;
  align-items: center;
  gap: var(--_rt-directions-pane-addresses-icon-gap);

  padding: var(--_rt-directions-pane-addresses-padding);
  background-color: var(--_rt-directions-pane-addresses-background-color);
  border: var(--_rt-directions-pane-addresses-border);

  font-size: var(--_rt-directions-pane-addresses-font-size);

  border-radius: var(--_rt-directions-pane-addresses-border-radius);

  img {
    width: var(--_rt-directions-pane-addresses-icon-width);
    height: 26px;
  }
}

.rt-address-origin .rt-input-container::before {
  content: "";
  min-width: var(--_rt-autocomplete-width-icons);
  height: 26px;
  background-image: url("/img/pin/pin-user.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

// * total time and distance area
.rt-total-travel-distance-and-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;

  padding: 1em;
  background-color: #efefef;
}

// * instructions area

.rt-instructions-label {
  display: flex;
  align-items: center;
  gap: 10px;

  font-weight: typography.$fw-700;
  font-size: 16px;

  img {
    width: var(--_rt-directions-pane-instructions-label-icon-width);
  }
}

.rt-instructions-label--origin {
  margin-bottom: 20px;
}

.rt-instructions-list {
  margin-bottom: 20px;
}

.rt-instructions-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;

  &:not(:last-child) {
    margin-bottom: 0.5em;
  }

  > div {
    display: flex;

    align-items: flex-start;
    gap: 1em;

    font-size: 15px;
    font-weight: typography.$fw-400;

    strong {
      font-weight: typography.$fw-700;
    }
  }
}
