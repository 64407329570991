@use "../abstracts/mixins";

// * Layout stuff
.rt-controls {
  padding-block: 1em;

  @include mixins.mq(large-desktop, down) {
    padding-bottom: 2em;
  }
}

.rt-controls__grid {
  display: grid;
  gap: 1em;

  @include mixins.mq(desktop, up) {
    grid-auto-flow: column;
    grid-auto-columns: 1fr 1fr auto;

    width: 900px;
    margin-inline: auto;
  }
}

// * client-related
.rt-controls {
  .rt-autocomplete-wrapper {
    height: 100%;
  }
}
