.rt-next-opening {
  display: inline-flex;

  background-color: white;
  border: 1px solid currentColor;
  padding: 0.25em 0.75em;

  font-size: 12px;
  color: var(--rt-color-text);
}
