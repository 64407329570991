@use "../abstracts/mixins";

.rt-list-container {
  // * list container
  --_rt-list-container-padding-block-mobile: 32px;
  --_rt-list-container-padding-block-desktop: 29px;

  // * store count
  --_rt-store-count-text-alignement: start; // [start, center, end]
  --_rt-store-count-font-size: 15px;
  --_rt-store-count-color: inherit;
  --_rt-store-count-margin-bottom: 25px;

  // * store list
  --_rt-store-list-row-gap: 50px;
  --_rt-store-list-column-gap: 31px;
  --_rt-store-card-min-width: 375px;
}

// * list container
.rt-list-container {
  // We need this overflow-y: auto in order to make this
  // list container scrollable
  overflow-y: auto;

  background-color: var(--rt-color-neutral);
  padding-block: var(--_rt-list-container-padding-block-mobile);

  @include mixins.mq(desktop, up) {
    padding-block: var(--_rt-list-container-padding-block-desktop);
  }
}

// * store count
.rt-store-count {
  display: block;

  margin-bottom: var(--_rt-store-count-margin-bottom);

  font-size: var(--_rt-store-count-font-size);
  font-weight: 300;
  text-align: var(--_rt-store-count-text-alignement);
  color: var(--_rt-store-count-color);
}

// * store list
.rt-store-list {
  // The store list is a fluid grid, with maximum 2 columns,
  // and with its element never less wide than a certain length
  @include mixins.auto-filling-grid(
    2,
    var(--_rt-store-card-min-width),
    var(--_rt-store-list-row-gap),
    var(--_rt-store-list-column-gap)
  );
}

// * back to top
.rt-back-to-top {
  @include mixins.mq(desktop, down) {
    display: none;
  }
}
