@use "../abstracts/mixins";
@use "../abstracts/typography";

.rt-powered-by {
  --_rt-powered-by-gap: 1em;
  --_rt-powered-by-padding-block: 24px;
  --_rt-powered-by-font-size: inherit;
  --_rt-powered-by-background-color: revert;
  --_rt-powered-by-color: inherit;
}

.rt-powered-by {
  // powered-by footer is a flex-item, living in a flex container
  // which is the page container (".rt-homepage")
  // so we take advantage of that, setting the margin-top of this element to auto
  // so that we always obtain a so called "sticky footer"
  margin-top: auto;

  background-color: var(--_rt-powered-by-background-color);

  font-size: var(--_rt-powered-by-font-size);
  font-weight: typography.$fw-700;
  color: var(--_rt-powered-by-color);

  .rt-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--_rt-powered-by-gap);

    padding-block: var(--_rt-powered-by-padding-block);

    text-align: center;

    @include mixins.mq(desktop, up) {
      flex-direction: row;

      // We always want the "RetailTune" logo
      // to be alone on the right end of the footer
      // so, we take advantage of the flex container
      // and we set a margin-left: auto on this element
      // which *MUST* always be the last child of its container
      .rt-powered-by__link:last-child {
        margin-left: auto;
      }
    }
  }

  .rt-powered-by__link:has(.rt-logo-retail) {
    font-weight: typography.$fw-400;
    color: #666;
  }

  .rt-logo-retail,
  .rt-logo-tune {
    font-family: Arial, Helvetica, sans-serif;
  }

  .rt-logo-retail {
    color: #1d1d1b;
    font-weight: 700;
  }

  .rt-logo-tune {
    color: #f0ad4e;
    font-weight: 400;
  }
}
