@use "../abstracts/colors";
@use "../abstracts/typography";
@use "../abstracts/mixins";

.gm-style-iw {
  max-width: revert !important;
  max-height: revert !important;
  padding: 0 !important;
}

.gm-style-iw-d {
  max-height: revert !important;
}

.rt-iw {
  --rt-iw-min-width: 200px;
  --rt-iw-max-width: 350px;
  --rt-iw-max-height: 350px;
  --rt-iw-padding: 24px;
  --rt-iw-info-gap: 0.15em;

  min-width: var(--rt-iw-min-width);
  max-width: var(--rt-iw-max-width);
  max-height: var(--rt-iw-max-height);
  padding: var(--rt-iw-padding);
  background-color: white;

  overflow-y: auto;

  &__next-opening {
    margin-bottom: 10px;
    font-size: 12px;
  }

  // name
  &__name {
    display: block;

    margin-bottom: 14px;

    font-weight: typography.$fw-700;
  }

  // info
  &__info {
    margin-bottom: 20px;

    font-weight: 300;

    > * {
      display: block;
    }

    > * + * {
      margin-top: var(--rt-iw-info-gap);
    }
  }

  &__contacts {
    a {
      display: inline-flex;
      align-items: center;
      gap: 0.5em;

      overflow-wrap: anywhere;
    }

    > * + * {
      margin-top: var(--rt-iw-info-gap);
    }
  }

  // cta
  &__cta {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    > * {
      flex: 1 1 150px;
      font-size: 13px;
    }
  }
}
