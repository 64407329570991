// * Imports for "DINPro"
@font-face {
  src: url("/font/DINPro/DINPro-Regular.woff2");
  font-family: DINPro;
  font-weight: 400;
}

@font-face {
  src: url("/font/DINPro/DINPro-Bold.woff2");
  font-family: DINPro;
  font-weight: 700;
}
