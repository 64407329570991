@use "../abstracts/typography";

.rt-filters {
  display: grid;
  grid-template-rows: auto 1fr auto;

  height: 100%;

  > * {
    padding-inline: 1em;
  }

  &__title {
    display: block;

    font-size: 18px;

    // number of selected items

    &::after {
      content: "(" attr(data-selected-options) ")";

      display: inline-block;
      margin-left: 0.25em;
    }

    &:not([data-selected-options]),
    &[data-selected-options=""],
    &[data-selected-options="0"] {
      &::after {
        content: "";
      }
    }
  }

  // * header
  &__header {
    display: flex;
    gap: 30px;

    padding-bottom: 50px;
  }

  &__heading {
    margin-right: auto;

    font-size: 20px;
    font-weight: typography.$fw-700;
  }

  &__reset {
    text-decoration: underline;
  }

  &__header .rt-btn-close img {
    width: 17px;
  }

  // * body
  &__body {
    overflow: hidden auto;
  }

  &__applied-filters {
    border-top: 1px solid var(--rt-color-neutral-2);
    padding-block: 10px 20px;

    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 1em;

      padding-top: 20px;
    }
  }

  &__applied-filter {
    display: flex;
    align-items: center;
    gap: 0.5em;

    padding: 0.35em 1em;
    border: 1px solid currentColor;

    border-radius: 4px;

    .rt-btn-close img {
      width: 10px;
    }
  }

  &__categories {
    border-top: 1px solid var(--rt-color-neutral-2);
  }

  &__category ul {
    display: grid;
    gap: 1em;
  }

  &__option {
    display: flex;
    gap: 11px;

    cursor: pointer;
  }

  // * footer
  &__footer {
    padding-top: 2em;
  }

  &__show-results {
    width: 100%;
  }
}
