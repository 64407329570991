@use "../abstracts/mixins";

.rt-accordion {
  --_rt-accordion-border-bottom: 1px solid var(--rt-color-neutral-2);
  --_rt-accordion-heading-padding-block: 12px;
  --_rt-accordion-body-padding-block: 0 26px;
}

.rt-accordion {
  // * General styling
  border-bottom: var(--_rt-accordion-border-bottom);

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;

    padding-block: var(--_rt-accordion-heading-padding-block);

    cursor: pointer;

    img {
      width: 10px;
    }
  }

  &--open &__body {
    padding-block: var(--_rt-accordion-body-padding-block);
  }

  // * Rotate chevron when opened
  &__heading img {
    transition: rotate 300ms;
  }

  &--open &__heading img {
    rotate: 0.5turn;
  }

  // * Opening/closing logic
  &__body {
    display: grid;
    grid-template-rows: 0fr;

    transition: 300ms grid-template-rows;

    > div {
      overflow: hidden;
    }
  }

  &--open &__body {
    grid-template-rows: 1fr;
  }
}
