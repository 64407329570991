@use "../abstracts/typography";
@use "../abstracts/colors";
@use "../abstracts/mixins";

:where(.rt-homepage, .rt-concierge, .rt-seo-page) {
  // The only reason we need these root elements to be setted
  // as flex containers is to guarantee that the "powered by" footer
  // sticks to the very bottom of the page
  // (assigning it a margin-top: auto)
  // This way, the footer will sitck to the bottom
  // even when the content before the footer is not enough
  // to fill the 100vh space
  display: flex;
  flex-direction: column;

  // Of course, we always want our page to be at least 100vh tall
  min-height: 100vh;

  // * Basic typography rules
  // These rules are defined here in order to be inherited by default
  // by each and every children
  font-family: var(--rt-font-family-primary, sans-serif);
  font-size: 14px;
  font-weight: typography.$fw-400;
  line-height: 1.5;
  color: var(--rt-color-text, black);

  accent-color: var(--rt-color-brand, black);

  * {
    @include mixins.scrollbar(
      $clr-scrollbar-thumb: var(--rt-color-brand, black),
      $clr-scrollbar-track: #f9f9f9,
      $scrollbar-width: 6px,
      $border-radius: 0
    );
  }
}

//
body {
  -webkit-font-smoothing: antialiased;
}
