@use "sass:map";
@use "breakpoints";

// * Mixin that styles a scrollbar for the element the mixin is applied on
// * and all of its children. It takes as arguments:
// * - The thumb color
// * - The track color
// * - The scrollbar's width (only for webkit)
// * - The scrollbar's border radius (only for webkit)
@mixin scrollbar(
  $clr-scrollbar-thumb,
  $clr-scrollbar-track,
  $scrollbar-width: 4px,
  $border-radius: 0
) {
  // Webkit
  &::-webkit-scrollbar {
    width: $scrollbar-width;
    background-color: $clr-scrollbar-track;
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $clr-scrollbar-thumb;
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar:horizontal {
    height: $scrollbar-width;
  }

  // Firefox-only
  @supports (-moz-appearance: none) {
    & {
      scrollbar-color: $clr-scrollbar-thumb $clr-scrollbar-track;
      scrollbar-width: thin;
    }
  }
}

// * Mixin that creates a media query given:
// * - a breakpoint name (must be a key of breakpoints.$breakpointsMap)
// * - a direction (must be either up or down)
@mixin mq($breakpoint-name, $direction: up) {
  @if not map-has-key($map: breakpoints.$breakpointsMap, $key: $breakpoint-name) {
    @error "`#{$breakpoint-name}` is not a valid breakpoint";
  }

  $_breakpoint-value: map.get(
    $map: breakpoints.$breakpointsMap,
    $key: $breakpoint-name,
  );

  @if $direction == down {
    @media (max-width: calc($_breakpoint-value - 0.1px)) {
      // @debug "mq down: #{$breakpoint-name} [#{$_breakpoint-value}]";
      @content;
    }
  } @else {
    @media (min-width: $_breakpoint-value) {
      // @debug "mq up: #{$breakpoint-name} [#{$_breakpoint-value}]";
      @content;
    }
  }
}

// * Mixin that creates an auto-filling grid given:
// * - a maximum number of columns
// * - a minimum width for each column
// * - a row gap
// * - a column gap (by default, equal to the row gap)
@mixin auto-filling-grid($max-num-columns, $min-width-column, $row-gap, $column-gap: $row-gap) {
  // The total number of gaps
  $_column-gap-total-count: calc(#{$max-num-columns} - 1);

  // The total width of those gaps
  $_column-gap-total-width: calc(#{$_column-gap-total-count} * #{$column-gap});

  // The maximum width each column should be allowed to grow
  $_max-width-column: calc((100% - #{$_column-gap-total-width}) / #{$max-num-columns});

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(min(100%, max(#{$min-width-column}, #{$_max-width-column})), 1fr)
  );
  gap: $row-gap $column-gap;
}
