@use "../abstracts/mixins";
@use "../abstracts/typography";

.rt-modal {
  inset: 0;

  background-color: white;
  margin: auto;
  padding: 42px 67px;
  border: 0;

  color: inherit;

  border-radius: 0;

  &::backdrop {
    background-color: rgb(0 0 0 / 0.5);
  }
}

// * position-consent modal
.rt-position-consent-modal {
  width: min(90%, 650px);

  text-align: center;

  .rt-btn-close {
    position: absolute;
    top: 20px;
    right: 20px;

    img {
      width: 17px;
    }
  }

  .rt-modal__title {
    margin-bottom: 27px;

    font-size: 16px;
    font-weight: typography.$fw-700;
    line-height: 1.2;
  }

  .rt-modal__message {
    margin-bottom: 27px;
  }

  .rt-modal__actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
    max-width: 350px;
    margin: auto;
  }

  .rt-modal__actions > * {
    flex: 1 1 150px;
  }
}
