@use "../abstracts/mixins";

// This container is needed for the homepage, where we want
// header + controls + map-list to be exctly 100vh tall
// This also implies having a flex container:
// this way, we can set flex: 1 on the map-list container
// so that it stretches to fill the remaining space
// (i.e: 100vh - header's height - controls' height)
.rt-viewport-container {
  @include mixins.mq(desktop, up) {
    display: flex;
    flex-direction: column;

    height: 100vh;
  }
}
