@use "../abstracts/mixins";

.rt-header {
  --_rt-header-gap: 30px;
  --_rt-header-padding-block: 2em;
  --_rt-header-background-color: revert;
  --_rt-header-color: inherit;

  @include mixins.mq(desktop, up) {
    --_rt-header-gap: 50px;
  }
}

// * Layout stuff
.rt-header__grid {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: var(--_rt-header-gap);
}

:is(.rt-header-1, .rt-header-2) {
  .rt-header__grid {
    grid-template-columns: 1fr auto 1fr;
  }

  .rt-header__logo {
    grid-column: 2 / 3;
  }

  .rt-header__nav:first-child {
    justify-self: start;
  }

  .rt-header__nav ~ .rt-header__nav {
    justify-self: end;
  }

  .rt-header__logo {
    text-align: center;
  }
}

// .rt-header-3 {
.rt-header {
  .rt-header__grid {
    grid-template-columns: 1fr;
  }

  .rt-header__logo {
    order: -1;
  }
}

// * General stuff
.rt-header {
  // * Box model
  padding-block: var(--_rt-header-padding-block);
  background-color: var(--_rt-header-background-color);

  // * Typography
  color: var(--_rt-header-color);

  // * Links
  &__nav {
    display: flex;
    align-items: center;
    gap: 0.5em var(--_rt-header-gap);
    justify-content: flex-end;
    flex-wrap: wrap;

    text-align: end;

    @include mixins.mq(desktop, down) {
      align-self: start;
    }

    @include mixins.mq(large-desktop, up) {
      max-width: 250px;
    }
  }

  // * Logo
  &__logo {
    a {
      display: block;
      width: max-content;
    }

    // Set dimensions for logo image
    img {
      width: 185px;

      margin-bottom: 0.5em;
    }
  }

  // * Advanced search icon
  &__advanced-search {
    max-width: 30px;
  }
}
