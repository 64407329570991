@use "../abstracts/mixins";

.rt-header-and-controls {
  @include mixins.mq(large-desktop, up) {
    display: grid;
    grid-template-columns: [header-start] auto [controls-start] 1fr [controls-end] auto [header-end];
    align-items: center;

    position: relative;
    z-index: 100;

    > .rt-header {
      grid-area: header;
    }

    > .rt-controls {
      grid-area: controls;

      width: max-content;

      justify-self: center;
    }
  }
}
