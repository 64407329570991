@use "../abstracts/colors";
@use "../abstracts/typography";
@use "../abstracts/mixins";

.rt-btn {
  --_rt-btn-padding: 0.75em 1.5em;
  --_rt-btn-border-radius: 100vmax;
  --_rt-btn-font-size: inherit;
  --_rt-btn-font-weight: #{typography.$fw-700};

  // primary
  --_rt-btn-primary-background-color: var(--rt-color-brand);
  --_rt-btn-primary-border: 1px solid var(--rt-color-brand);
  --_rt-btn-primary-color: white;

  // secondary
  --_rt-btn-secondary-background-color: white;
  --_rt-btn-secondary-border: 1px solid white;
  --_rt-btn-secondary-color: var(--rt-color-text);

  // tertiary
  --_rt-btn-tertiary-background-color: var(--rt-color-neutral);
  --_rt-btn-tertiary-border: 1px solid var(--rt-color-neutral);
  --_rt-btn-tertiary-color: var(--rt-color-text);
}

.rt-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 1em;

  padding: var(--_rt-btn-padding);

  font-size: var(--_rt-btn-font-size);
  font-weight: var(--_rt-btn-font-weight);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.3px;

  border-radius: var(--_rt-btn-border-radius);

  // hover state
  transition: opacity 300ms;

  &:hover {
    opacity: 0.8;
  }

  &--primary {
    background-color: var(--_rt-btn-primary-background-color);
    color: var(--_rt-btn-primary-color);
    border: var(--_rt-btn-primary-border);
  }

  &--secondary {
    background-color: var(--_rt-btn-secondary-background-color);
    color: var(--_rt-btn-secondary-color);
    border: var(--_rt-btn-secondary-border);
  }

  &--tertiary {
    background-color: var(--_rt-btn-tertiary-background-color);
    color: var(--_rt-btn-tertiary-color);
    border: var(--_rt-btn-tertiary-border);
  }

  &-geo {
    justify-content: space-between;

    @include mixins.mq(small-mobile, down) {
      padding-inline: 0.6em;
    }
  }

  &-filters {
    justify-content: space-between;
    border: 1px solid #999;
  }
}
