.rt-sidebar {
  --_rt-sidebar-background-color: white;
  --_rt-sidebar-padding: 30px;
  --_rt-sidebar-border: none;
  --_rt-sidebar-overlay-color: rgba(0, 0, 0, 0.5);
  --_rt-sidebar-relative-width: 90%;
  --_rt-sidebar-max-width: 400px;
}

// * the outer-most div (.rt-sidebar) serves as an overlay
.rt-sidebar {
  position: fixed;
  inset: 0;
  z-index: 1000;

  display: grid;

  transition-duration: 300ms;
  transition-timing-function: ease-out;
  transition-property: background-color, visibility;

  visibility: hidden;
}

// .rt-sidebar__content is the actual sidebar that slides in and out the viewport
.rt-sidebar__content {
  width: min(var(--_rt-sidebar-relative-width), var(--_rt-sidebar-max-width));
  height: 100%;

  background-color: var(--_rt-sidebar-background-color);
  padding: var(--_rt-sidebar-padding);
  border: var(--_rt-sidebar-border);
  overflow-y: auto;

  transition-duration: 300ms;
  transition-timing-function: ease-out;
  transition-property: translate;
}

// * left and right sidebar differences
.rt-sidebar--left {
  justify-items: left;

  .rt-sidebar__content {
    translate: -100% 0;
  }
}

.rt-sidebar--right {
  justify-items: right;

  .rt-sidebar__content {
    translate: 100% 0;
  }
}

// * sidebar visibility
.rt-sidebar--visible {
  background-color: var(--_rt-sidebar-overlay-color);
  visibility: visible;

  .rt-sidebar__content {
    translate: 0 0;
  }
}
